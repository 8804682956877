<template>
  <v-sheet elevation="0">
    <div class="pl-4">
      <v-checkbox
        v-model="importances"
        class="mt-0"
        label="A"
        value="A"
      />
      <v-checkbox
        v-model="importances"
        class="mt-0"
        label="B+（論文式監査論のみ使用）"
        value="B+"
      />
      <v-checkbox
        v-model="importances"
        class="mt-0"
        label="B"
        value="B"
      />
      <v-checkbox
        v-if="isKeieigakuPurchased"
        v-model="importances"
        class="mt-0"
        label="C+ （経営学のみ使用）"
        value="C+"
      />
      <v-checkbox
        v-model="importances"
        class="mt-0"
        label="C"
        value="C"
      />
    </div>
    <v-row
      class="no-gutters pl-5"
      style="margin-top: -10px;"
    >
      <span class="text-caption x-small-text">(※) 論文式監査論では暗記優先度を示しております。重要度選択の詳細は<a
        rel="noopenner"
        target="_blank"
        href="https://drive.google.com/file/d/1enJeP2ji3Pcsd4pJzW0Gzzqt-dc9ugU1/view?usp=drive_link"
      >こちら</a>をご参照ください。</span>
    </v-row>
  </v-sheet>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "SelectImportance",
  data() {
    return {
      importances: ["A", "B", "C"],
      isKeieigakuPurchased: false,
    };
  },
  computed: {
    ...mapGetters(["importance"]),
  },
  watch: {
    importances(newValue) {
      this.$store.dispatch("updateSelectedConditions", {
        importance: newValue,
      });
    },
  },
  mounted() {
    this.importances = this.importance;
    this.fetchPurchasedMaterials();
  },
  methods: {
    fetchPurchasedMaterials() {
      axios.get("/api/v1/users/purchased_materials").then((res) => {
        this.isKeieigakuPurchased = res.data.includes("経営学");
      });
    },
  },
};
</script>

<style scoped>
.x-small-text {
  font-size: 0.5rem;
  color: #7c7c7c;
}
</style>
