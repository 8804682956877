<template>
  <v-row justify="space-around">
    <v-sheet
      class="pa-0 col-11"
      outlined="outlined"
    >
      <v-card-title class="grey white--text">
        一問一答問題選択
      </v-card-title>
      <div class="x-small-text pa-4 px-3">
        ※目標年度は冊子版の問題集の表紙に記載されているものと対応しております。また、Web問題集に収録している問題集および掲載期間は
        <a
          rel="noopener"
          target="_blank"
          href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRQlAANgybhXi5WwLvVq9hKwCTNWvirfsok71JlffwWJe3y5py339geA_5XodktrfgY0GKlD5wdJq2p/pubhtml"
        >こちら</a>
        になります。
        <br>
        ※2024年目標_短答式_監査論には改正が反映されていない問題が含まれておりますので、2025年目標の問題をご利用いただくことを推奨いたします。なお、2024年目標_短答式_監査論に掲載の問題のうち、改正により内容が大きく変わる章や節には（改正未反映）と付記しております。
      </div>
      <v-stepper
        v-model="step"
        non-linear="non-linear"
        vertical="vertical"
      >
        <v-stepper-step
          editable="editable"
          step="1"
        >
          カテゴリ
        </v-stepper-step>
        <v-stepper-content step="1">
          <select-category
            :loading.sync="loading"
            :api-failed.sync="apiFailed"
          />
          <v-row class="ma-2">
            <v-btn
              block="block"
              color="primary"
              dark="dark"
              @click="step=2"
            >
              次へ
            </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-step
          editable="editable"
          step="2"
        >
          重要度
        </v-stepper-step>
        <v-stepper-content step="2">
          <select-importance />
        </v-stepper-content>
        <v-stepper-step
          editable="editable"
          step="3"
        >
          その他オプション
        </v-stepper-step>
        <v-stepper-content step="3">
          <select-option />
        </v-stepper-content>
      </v-stepper>
      <v-row justify="space-around">
        <v-btn
          class="ma-5"
          color="orange"
          large="large"
          dark="dark"
          min-width="300px"
          @click="getQuestions"
        >
          開始
        </v-btn>
      </v-row>
    </v-sheet>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate="indeterminate"
        size="64"
      />
    </v-overlay>
    <v-snackbar
      v-model="noQuestions"
      bottom="bottom"
      color="secondary"
    >
      該当する問題がありません
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="noQuestions = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="apiFailed"
      bottom="bottom"
      color="error"
    >
      データの取得に失敗しました、再度お試しください
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="apiFailed = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectOption from "../components/SelectQuestions/selectOption";
import SelectCategory from "../components/SelectQuestions/selectCategory";
import SelectImportance from "../components/SelectQuestions/selectImportance.vue";
import * as Sentry from "@sentry/vue";

export default {
  name: 'SelectQuestions',
  components: { SelectCategory, SelectOption, SelectImportance },
  data() {
    return {
      step: 1,
      loading: false,
      noQuestions: false,
      apiFailed: false,
    }
  },
  computed: {
    ...mapGetters(['questions']),
  },
  methods: {
    async getQuestions() {
      this.loading = true;
      await this.$store.dispatch('getQuestions');
      this.loading = false;
      if (this.questions.length < 1) {
        this.noQuestions = true
      } else {
        try {
          await this.$router.push('/question/1');
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    },
  },
}
</script>

<style scoped>

.x-small-text {
  font-size: 0.7rem;
  color: #7c7c7c;
}

</style>
