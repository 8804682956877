<template>
  <v-sheet elevation="0">
    <v-row class="justify-start no-gutters">
      <v-select
        class="ml-2 small-select"
        outlined="outlined"
        dense="dense"
        hide-details="hide-details"
        color="primary"
        :items="examOptions"
        :value="exam"
        default-value="2024"
        @change="updateSelectedExam"
      />
    </v-row>
    <v-row class="justify-start">
      <v-btn
        class="mr-0 ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="selectAll"
      >
        全て選択
      </v-btn>
      <v-btn
        class="ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="deselectAll"
      >
        選択解除
      </v-btn>
    </v-row>
    <v-treeview
      v-if="categories.length > 0"
      v-model="selectedCategories"
      selectable="selectable"
      :selection-type="'leaf'"
      :open="initialOpen"
      open-on-click="open-on-click"
      hoverable="hoverable"
      dense="dense"
      :items="categories"
    >
      <template v-slot:label="{ item }">
        <template v-if="isLogin">
          <div
            class="text-wrap my-1 caption"
            :class="{ custom: $vuetify.breakpoint.xs}"
          >
            {{ categoryText(item.name) }}
          </div>
          <v-progress-linear
            height="20"
            color="pink lighten-5"
            background-color="grey lighten-3"
            :value="item.progress * 100"
          >
            <template>
              <small>{{ (item.progress * 100).toFixed(1) }}% ({{ item.solved_count }}/{{ item.question_count }})</small>
            </template>
          </v-progress-linear>
        </template>
      </template>
    </v-treeview>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";
import * as Sentry from "@sentry/vue";

export default {
  name: "SelectCategory",
  data() {
    return {
      categories: [],
      subCatIds: [],
      selectedCategories: [],
      initialOpen: ["短答式", "論文式"],
      examOptions: [
        { text: '2024年目標（2024/2025年目標）', value: '2024' },
        { text: '2025年目標（2025/2026年目標）', value: '2025' },
      ]
    }
  },
  computed: {
    ...mapGetters(["isLogin", "exam", "category"]),
  },
  watch: {
    selectedCategories() {
      const payload = { category: this.selectedCategories };
      this.$store.dispatch('updateSelectedConditions', payload);
    },
    isLogin() {
      this.fetchCategories()
    },
    exam() {
      this.fetchCategories()
    }
  },
  mounted() {
    this.selectedCategories = this.category
    this.fetchCategories()
  },
  methods: {
    selectAll() {
      this.selectedCategories = this.subCatIds
    },
    deselectAll() {
      this.selectedCategories = []
    },
    selectByCategoryName(categoryName) {
      if (!categoryName) return;
      const { id } = findCategory([...this.categories], categoryName)
      this.selectedCategories = [id];
    },
    categoryText(category_name) {
      // 掲載期間を末尾に追加
      const list = [
        {year: '2023', name: '短答式', newName: '短答式（掲載期間：2024年8月末まで）'},
        {year: '2023', name: '論文式', newName: '論文式（掲載期間：2024年8月末まで）'},
        {year: '2024', name: '短答式', newName: '短答式（掲載期間：2025年5月末まで）'},
        {year: '2024', name: '論文式', newName: '論文式（掲載期間：2025年8月末まで）'},
        {year: '2025', name: '短答式', newName: '短答式（掲載期間：2026年5月末まで）'},
      ]
      
      if (category_name === '短答式' || category_name=== '論文式') {
        return list.find(item => item.year === this.exam && item.name === category_name).newName
      }
      return category_name
    },
    updateSelectedExam(e) {
      this.$store.dispatch('updateSelectedConditions', { exam: e })
    },
    async fetchCategories() {
      if (!this.isLogin) return; 

      this.$emit('update:loading', true)
      const url = `/api/v1/users/progress?exam=${this.exam}`
      this.categories = []
      this.subCatIds = []

      await axios.get(url)
        .then((res) => {
          this.categories = res.data.items;
          this.subCatIds = res.data.sub_cat_ids;
        }).catch((err) => {
          // check if Axios Network Error の場合は自動ログアウトによるエラーなので、エラーをキャプチャしない
          if (err?.response !== undefined) {
            Sentry.captureException(err);
          }
          this.$emit('update:apiFailed', true);
        }).finally(() => {
            this.$emit('update:loading', false);
          }
        );
      this.selectByCategoryName(this.$route.query.category)
    }
  }
}

const findCategory = (categoryList, categoryName) => {
  const matchedCategory = categoryList.filter((category) => category.name === categoryName)
  if (matchedCategory.length > 0) return matchedCategory[0]

  return categoryList.map((category) => {
    if (category.children) {
      return findCategory(category.children, categoryName)
    }
  }).filter(value => value !== undefined)[0]
}
</script>

<style scoped lang="scss">
.custom {
  font-size: 12px;
  line-height: 1.1;
}

.small-select {
  font-size: 0.7rem;
}

.x-small-text {
  font-size: 0.7rem;
  color: #7c7c7c;
}
</style>

